import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";
import { default_goodbye } from "../../utils/consts";

export interface GoodbyeSectionProps {
  disabled: boolean;
}

export function GoodbyeSection(props: GoodbyeSectionProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();
  const { disabled } = props;
  return (
    <Controller
      name="goodbye_message"
      control={control}
      render={({ field }) => {
        const value = field.value || default_goodbye()
        return(
        <TextField
          {...field}
          value={value}
          disabled={disabled}
          error={errors.goodbye_message !== undefined}
          helperText={
            errors.goodbye_message ? errors.goodbye_message?.message : "　"
          }
          sx={{
            maxWidth: 540,
            width: "100%",
          }}
          className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
          type="text"
          inputProps={{
            maxLength: 50,
            style: {
              padding: "10px 12px",
            },
          }}
        />
      )}}
    />
  );
}
