import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Controller, useFormContext } from "react-hook-form";
import { useTimezoneSelect } from "react-timezone-select";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";
import { useMediaMobile } from "../../utils/utils";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export interface CompanySectionProps {
  disabled: boolean
}
export function CompanySection(props: CompanySectionProps) {
  const {disabled} = props
  const isMobile = useMediaMobile();
  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();

  const zones: {
    [key: string]: string;
  } = {};
  Intl.supportedValuesOf("timeZone").forEach((tz) => (zones[tz] = tz));

  const { options } = useTimezoneSelect({
    labelStyle: "original",
    timezones: zones,
  });

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      spacing={0.5}
    >
      <Grid item xs={12} md={2} lg={1.2}>
        <Typography variant="body1" sx={{ marginBottom: isMobile ? 0 : 1 }}>
          Business Name
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={10}>
        <Controller
          name="company_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={errors.company_name !== undefined}
              helperText={
                errors.company_name ? errors.company_name?.message : "　"
              }
              type="text"
              sx={{
                maxWidth: 540,
                width: "100%",
              }}
              disabled={disabled}
              inputProps={{
                maxLength: 50,
                style: {
                  padding: "10px 12px",
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={2} lg={1.2}>
        <Typography variant="body1" sx={{ marginBottom: isMobile ? 0 : 1 }}>
          Time zone
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={10}>
        <Controller
          name="time_zone"
          control={control}
          render={({ field }) => {
            const value = field.value
              ? field.value
              : Intl.DateTimeFormat().resolvedOptions().timeZone;

            return (
              <Select
                value={value}
                onChange={field.onChange}
                sx={{
                  maxWidth: 540,
                  width: "100%",
                }}
                disabled={disabled}
              >
                {options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            );
          }}
        />
      </Grid>
    </Grid>
  );
}
