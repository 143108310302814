import { ICityResult } from "../interfaces/ICityResult";
export const MAX_CARD_LENGTH = 1000;

export function default_location(): ICityResult {
  const city = process.env.REACT_APP_DEFAULT_CITY;
  const region = process.env.REACT_APP_DEFAULT_REGION;
  const country = process.env.REACT_APP_DEFAULT_COUNTRY;
  if (city && region && country)
    return { city: city, region: region, country: country, is_preferred: true };
  return { city: "Toronto", region: "ON", country: "CA", is_preferred: true };
}

export function default_voice() {
  const voice = process.env.REACT_APP_DEFAULT_VOICE;
  if (voice) return voice;
  return "alice";
}

export function default_welcome(): string {
  const welcome = process.env.REACT_APP_WELCOME_DEFAULT;
  if (welcome) return welcome;
  return "Hello, thank you for calling {company_name}. How can I help you?";
}
export function default_goodbye(): string {
  return "";
}

export function default_timezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function default_template() {
  const template = process.env.REACT_APP_DEFAULT_TEMPLATE;
  if (template) return template;
  return "ai_receptionist";
}
