import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { titleCase, useMediaMobile } from "../../utils/utils";
import { VoiceSection } from "./VoiceSection";
import { useContext } from "react";
import ApiContext from "../../context/ApiContext";
import { Controller, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";
import { default_template } from "../../utils/consts";

export interface AIAgentSectionProps {
  disabled: boolean;
}
export function AIAgentSection(props: AIAgentSectionProps) {
  const { disabled } = props;
  const {
    data: { templates },
  } = useContext(ApiContext);
  const isMobile = useMediaMobile();
  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      spacing={0.5}
    >
      <Grid item xs={12} md={2} lg={1.2}>
        <Typography variant="body1" sx={{ marginBottom: isMobile ? 0 : 1 }}>
          Template
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={10}>
        <Controller
          name="template_key"
          control={control}
          render={({ field }) => {
            return (
              <Select
                {...field}
                defaultValue={default_template()}
                sx={{
                  maxWidth: 540,
                  width: "100%",
                }}
                disabled={disabled}
              >
                {templates?.map((template) => {
                  return (
                    <MenuItem
                      key={template.lookup_key}
                      value={template.lookup_key}
                    >
                      {titleCase(template.name)}
                    </MenuItem>
                  );
                })}
              </Select>
            );
          }}
        />
      </Grid>
      <Grid item xs={12} md={2} lg={1.2}>
        <Typography variant="body1" sx={{ marginBottom: isMobile ? 0 : 1 }}>
          Voice
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={10}>
        <VoiceSection disabled={disabled} />
      </Grid>
    </Grid>
  );
}
